
#header{
  position: sticky;
  top: 0;
  z-index: 998;
 }
  .navbox{
      display: flex;
      justify-content: space-around;
      background-color: #2b292a;
      width: 100%;
      height: 11vh;
    min-height: 70px;
      align-items: center;
      color: black;
      transition: all 0.5s ease;
      
       }
       .sticky{
        background-color: #2b292a;
        transition: all 1s ease;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 34px 40px -7px;
        margin-bottom: 5px;
       }
  #header #logo img{
    height: 68px;
    width: 300px;
    }
 .navitems{
     display: flex;
     list-style: none;
     /* border: 1px solid blue; */
     justify-content: space-around;
     margin-top: 30 px;
     color: black;

 }
 .navbox .menu-btn i{
     font-size: 38px;
     display: none;
     color: #d9b36d;
     cursor: pointer;
     transition: all 0.2s ease-in;
 }
 .nav-responsive img{
    width: 14vw;
    height: 8vh;
 }
 .navitems li {
    margin: 5px;
    
    
}

.navitems li a{
  text-decoration: none;
  font-size: 18px;
  padding: 0px 3px;
  color: white;
  font-weight: 600;
}
/* .navitems li a.active{
 text-decoration: none;
 font-size: 18px;
 font-weight: 600;
 padding: 0px 3px;
 color: #156eff;
} */
.navitems li a:hover,
.navitems li a:active,
.navitems li a:focus {
color: #d9b36d;
outline: none;
text-decoration: none;
}
.dropdown-menu{
min-width: 11.2rem;
}
#click{
    display: none;
}
.navbox .red-btn{
  background-color: #d9b36d;  
  border: none; margin-left: 12px;
  transition: all 0.7s ease;
}
.navbox .red-btn:hover{
  background-color: #d9b36d;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 0.7s ease;
}
@media (max-width: 850px)  {
    #click:checked ~ .menu-btn i:before{
  content: "\f00d";
    }
.navitems{
    flex-direction: column;
    position: absolute;
    top: 72.2px;
    background-color: #2b292a;
    width: 100%;
    height: 60vh;
    max-height: 280px;
    left: -100%;
    transition: all 0.3s ease;
    min-height: 230px;
}

#click:checked ~ .navitems{
    left: 0%;
}

.navbox .menu-btn i{
    display: block;
}
.navbox .red-btn{
 width: 131px;
 margin:0px auto;
}
}