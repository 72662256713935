.services{
/* border: 1px solid black; */
margin: 0px;
padding: 20px;  
background-color: #f7efda;



}
.services h1 {
    margin-left: 3%;
    text-align: left;
    font-weight: 700;
}
.services h2{
    text-align: left;
    margin-left: 3%;
    width: 70%;
    font-weight: 600;
}
.services p {
    width: 70%;
    line-height: normal;
    margin-left: 3%;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
}
.serviece1{
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}
.serviece2{
    display: flex;
    justify-content: space-around;
}
.card{
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.card-img-top{
   background-color: white;
    /* width: 150px;
    height: 150px; */
}
#knowmore{
    position: relative;
    left: 33%;
    width: 150px;
    height: 55px;
    background-color: #f9faff;
    color: black;
    border-radius: 3px;
    border: 1px solid black;
}
#knowmore:hover{
    background-color: #01014c;
    color: white;
    /* border: none; */
    
}
@media (max-width: 700px){
.serviece1{
    flex-wrap: wrap;
}
.serviece2{
    flex-wrap: wrap;
}

}