/* body{
    background-color: #f8f9fa;
} */
.firstcontainer{
    display: flex;
    /* border: 1px solid black; */
    /* height: 700px; */
    margin-top: 0px;
    justify-content: space-evenly;
    background-color: #fcf7ec;
}
.leftcontainer{
    /* padding-left: 55px; */
    width: 45%;
    /* border: 1px solid red; */

}

.boxitem1{
    display: flex;
}
.boxitem2{
    display: flex;
}
.boxitems{
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 10px;
    width: 300px;
    height: 300px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: black;
    background-color: white;
}
.boxitems h3{
    font-weight: 600;
    /* font-size: 26px; */

}
#boxitemsspecial{
background-color: #d9b36d;
color: white;
}
.boxitems:hover{
    background-color: #d9b36d;
    box-shadow: 10px 10px 5px #052f48;
    color: white;
   
}
.boxitems:hover i{
    color: white;
}
.boxitems img{
    color: white;
    width: 50px;
    height: 50px;
}
.boxitems i{
    color: rgb(1, 1, 76);
    width: 50px;
    height: 50px;
}

.rightcontainer{
    width: 45%;
    /* border: 1px solid red; */
    padding-top: 50px;

}
.rightcontainer h2 {
    text-align: left;
}
.rightcontainer p {
    font-size: 1.2em;
    line-height: 1.5rem;
    text-align: left;
}
.rightcontainer button{
    
    border-radius: 3px;
    width: 124px;
    height: 52px;
    color: white;
    background-color:#d9b36d ;
}
@media (max-width:850px) {
    .firstcontainer{
        flex-direction: column;
        
        /* margin-top: 0px; */
        margin: 10px 0px;
        justify-content: center;
        align-items: center;
    }
    .leftcontainer{
        /* display: none; */
        width: 100%;
    }
    .boxitem1{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .boxitem2{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .rightcontainer{
        width: 90%;
        /* border: 1px solid red; */
        padding-top: 100px;
    
    }
    #thirdpara{
        /* display: none; */

    }

}