.Our_Client{
    background-color: #efeef2;
    margin-top: 20px;
}
#Our_Client{
    text-align: center;
    padding-left: 3%;
    font-weight: 700;
    /* background-color: #efeef2; */

}
.slider {
    background: #efeef2;
    height: 300px;
    margin: 0px auto;
    overflow: hidden;
    position: relative;
    cursor: default;
    user-select: none;
    touch-action: none;
    display: flex;
    align-items: center;
    justify-content: center;
}


.slider:before {
    left: 0;
    top: 10;
}
.slider:after,
.slider:before {
    background: linear-gradient(
        to right,
        #efeef2 0%,
        hsla(0, 0%, 100%, 0) 100%
    );
    content: "";
    height: 75pt;
    position: absolute;
    width: 200px;
    z-index: 2;
}
.slider:after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}
.slider:after,
.slider:before {
    background: linear-gradient(
        to right,
        #efeef2 0%,
        hsla(0, 0%, 100%, 0) 100%
    );
    content: "";
    height: 75pt;
    position: absolute;
    width: 200px;
    z-index: 2;
}

.slider .slide-track-1 {
    animation: scroll-l 30s linear infinite;
    display: flex;
    width: 3500px;
   
}
.slider .slide-track-2 {
    animation: scroll-r 30s linear infinite;
    display: flex;
    width: 3500px;
}

.slider .slide {
    height: 150px;
    width: 300px;
    display: flex;
    align-items: center;
    text-align: center;
}
.slider .slide img {
    width: 180px;
    padding: 1pc;
    vertical-align: middle;
    margin: 0 auto;
    display: inline-block;
    max-width: 100%;
    height: auto;
}

@keyframes scroll-l {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 7));
    }
}
@keyframes scroll-r {
    100% {
        transform: translateX(0);
    }
    0% {
        transform: translateX(calc(-250px * 7));
    }
}